import {
  Company,
  CompanySearchParams,
} from '@/models/accounting';
import http from '../utils/http-client';

class AccountingService {
  static async listCompanies(params?: CompanySearchParams): Promise<Company[]> {
    const response: Company[] = await http.get('/api/accounting/companies', { params });

    return response;
  }

  static async showCompany(idCode?: string): Promise<Company> {
    const response: Company = await http.get(`/api/accounting/companies/show/${idCode}`);

    return response;
  }

  static async medicines(params?: any): Promise<any> {
    const response = await http.get('/api/accounting/medicines', { params });

    return response;
  }

  static async paymentOverview(params?: any): Promise<any> {
    const response = await http.get('/api/accounting/payment-overview', { params });

    return response;
  }

  static async medicineTotalPackages(id: string, params?: any): Promise<any> {
    const response = await http.get(`/api/inventory/producer/${id}/packages`, { params });

    return response;
  }
}

export default AccountingService;
