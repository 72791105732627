
import { defineComponent } from 'vue';
import store from '@/store';
import axios from 'axios';
import fileDownload from 'js-file-download';
import AccountingService from '@/services/AccountingService';
import InventoryService from '@/services/InventoryService';
import UiCombobox from '@/components/form/UiCombobox.vue';
import UiButton from '@/components/form/UiButton.vue';
import UiExpansionPanel from '@/components/form/UiExpansionPanel.vue';
import Pagination from '@/components/pagination/Pagination.vue';

export default defineComponent({
  name: 'PaymentOverview',
  data: () => ({
    searchInput: '',
    SearchTimeoutId: 0,
    companyModel: {} as any,
    company: {} as any,
    companies: [] as any,
    totalPackages: 0,
    drugs: [] as any,
    drugsTotal: 0,
    drugPage: 1,
    drugPages: 0,
  }),
  computed: {
    baseApiUrl() {
      return process.env.VUE_APP_BASE_API_URL;
    },
    currentItems(): number {
      const currentTotal = 10 * this.drugPage;
      return currentTotal >= this.drugsTotal ? this.drugsTotal : currentTotal;
    },
  },
  components: {
    UiCombobox,
    UiButton,
    UiExpansionPanel,
    Pagination,
  },
  watch: {
    searchInput: {
      immediate: true,
      handler(val) {
        window.clearTimeout(this.SearchTimeoutId);

        this.SearchTimeoutId = window.setTimeout(async () => {
          const params = {} as any;

          if (val && val.length) params.search_name = val;

          const companies = await AccountingService.listCompanies(params) as any;

          this.companies = companies.data;
        }, 300);
      },
    },
    'companyModel.short_code': {
      async handler(val) {
        if (!val) {
          this.company = {};
          this.drugsTotal = 0;
          this.totalPackages = 0;
          this.drugs = [];
          return;
        }

        try {
          this.company = await InventoryService.producer(val) as any;

          const params = {
            paid: 1,
          };

          const response = await AccountingService.medicineTotalPackages(
            this.company.id,
            params,
          );

          this.totalPackages = response.total;

          this.listMedicines();
        } catch (error) {
          this.drugsTotal = 0;
          this.totalPackages = 0;
          this.drugs = [];
        }
      },
    },
  },
  methods: {
    validateUrl(value: string) {
      return /(http(s?)):\/\//i.test(value) ? value : `https://${value}`;
    },
    transformDrugForDisplay(model: any) {
      const strengthText = model.details?.strength?.text;
      const strength = strengthText ? `(${strengthText})` : '';

      return {
        id: model.id,
        code: model.code,
        title: model.title,
        caption: `${model.details?.dosage_form?.text} ${strength}`,
        description: model.description,
        units: [],
        units_loaded: false,
        open: false,
      };
    },
    async listMedicines() {
      const params = {
        paid: 1,
        company_id: this.companyModel.id,
        page: this.drugPage,
        take: 10,
      };

      const response = await AccountingService.medicines(params);

      this.drugs = response.data.map((el: any) => this.transformDrugForDisplay(el));
      this.drugsTotal = response.total;
      this.drugPages = response.last_page;

      window.scroll({
        top: 0,
        behavior: 'smooth',
      });
    },
    async loadUnits(code: string) {
      const drug = this.drugs.find((el: any) => el.code === code);

      if (drug.units_loaded) return;

      const response = await InventoryService.drug(code) as any;

      drug.units_loaded = true;
      drug.units = response.units;
    },
    async downloadPaymentOverview(company_id?: string) {
      axios({
        url: `${this.baseApiUrl}/api/accounting/payment-overview`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Content-type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${store.getters.token}`,
        },
        params: {
          company_id,
          format: 'pdf',
        },
      }).then((response) => {
        fileDownload(response.data, company_id ? `${company_id} overview.pdf` : 'All overviews.pdf');
      });
    },
    async openPaymentOverview(company_id?: string) {
      axios({
        url: `${this.baseApiUrl}/api/accounting/payment-overview`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Content-type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${store.getters.token}`,
        },
        params: {
          company_id,
          format: 'pdf',
        },
      }).then((response) => {
        window.open(URL.createObjectURL(response.data));
      });
    },
  },
});
