
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UiExpansionPanel',
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get(): any {
        return this.modelValue;
      },
      set(val: any) {
        this.$emit('update:modelValue', val);
      },
    },
    icon() {
      return this.model ? 'angle-down' : 'angle-right';
    },
  },
  methods: {
    toggle() {
      if (!this.model) {
        this.$emit('open');
      }

      this.model = !this.model;
    },
  },
});
