import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7d8f18ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-expansion-panel" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "header",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _renderSlot(_ctx.$slots, "header", {}, undefined, true),
      _createVNode(_component_font_awesome_icon, {
        class: "icon",
        icon: ['fal', _ctx.icon]
      }, null, 8, ["icon"])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "content", {}, undefined, true)
    ], 512), [
      [_vShow, _ctx.model]
    ])
  ]))
}